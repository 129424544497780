


























































































































import BaseComponent from '@/core/base.component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'PrivacyContent',
})
export default class PrivacyContent extends BaseComponent {}
