






































































































































































































































































































































































































































import BaseComponent from '@/core/base.component';
import { Component } from 'vue-property-decorator';
import Register from '../Register.vue';

@Component({
  name: 'CurFnbOwner',
})
export default class CurFnbOwner extends BaseComponent {
  $refs!: {
    section03: HTMLFormElement;
    mySwiper: HTMLFormElement;
  };

  private isAnalysisVisible = false;
  private currentIdx = 0;
  private swiperOption: any = {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    loop: true,
    grabCursor: false,
    allowTouchMove: false,
    speed: 500,
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 1000,
      disableOnInteraction: true,
    },
  };

  private swiperAppReview: any = {
    slidesPerView: 1,
    loop: true,
    grabCursor: true,
    allowTouchMove: true,
    speed: 500,
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  get swiper() {
    return this.$refs.mySwiper.$swiper;
  }

  onSwipe(varuable: any) {
    let idx = varuable.swiper.activeIndex - 1;
    if (idx > 2) {
      idx = 0;
    }
    return (this.currentIdx = idx);
  }

  handleScroll() {
    const scrollTop = window.scrollY || window.pageYOffset;
    const section03 = this.$refs.section03;

    if (section03) {
      const rect = section03.getBoundingClientRect().top;
      if (rect < 300) {
        this.$refs.section03.classList.add('is-start');
        this.swiper.autoplay.start();
      }
    }
  }

  // 메뉴 분석 정보 더보기
  onClickAnalysisDetail() {
    this.isAnalysisVisible = !this.isAnalysisVisible;
  }

  mounted() {
    this.swiper.autoplay.stop();
  }
}
