




















































































































































































































import axios from 'axios';
import BaseComponent from '@/core/base.component';
import { ConsultRequestDto, ProformaConsultResultV3CreateDto } from '@/dto';
import { Component } from 'vue-property-decorator';
import { IsValidateHangeul, IsValidatePhone } from '@/core';
import { FNB_OWNER } from '@/common';
import ConsultResultV3Service from '@/services/consult-result-v3.service';
import PrivacyContent from '../_components/agreement/PrivacyContent.vue';
import NewFnbOwner from './_components/NewFnbOwner.vue';
import CurFnbOwner from './_components/CurFnbOwner.vue';

@Component({
  name: 'Register',
  components: { PrivacyContent, NewFnbOwner, CurFnbOwner },
})
export default class Register extends BaseComponent {
  $refs!: {
    container: HTMLFormElement;
    topForm: HTMLFormElement;
    radarChart: HTMLCanvasElement;
    mySwiper: HTMLFormElement;
  };

  private consultRequestDto = new ConsultRequestDto();
  private consultTotalCount: any = null;
  private consultTotalCountSticky: any = null;
  private deposit = 9;

  private isPrivacyChecked = true;
  private isVisibleStickyBtn = false;
  private headerBgOpacity = 0;

  private isCompleted = false;

  // get proform id
  private ipAddress = '';
  private fnbOwnerStatus = 'NEW_FNB_OWNER';
  private proformaConsultResultV3CreateDto = new ProformaConsultResultV3CreateDto();

  get userName() {
    return IsValidateHangeul(this.consultRequestDto.name);
  }

  // 휴대폰번호 유효성 체크
  get userPhone() {
    return IsValidatePhone(this.consultRequestDto.phone);
  }

  // 신청버튼 활성화 체크
  get isConsultBtnDisabled() {
    return this.userName && this.userPhone && this.isPrivacyChecked
      ? true
      : false;
  }

  // 상담받기 버튼
  onConsultBtnClicked() {
    ConsultResultV3Service.postConsult(this.consultRequestDto).subscribe(
      res => {
        if (res) {
          this.isCompleted = true;
          // this.$bvToast.toast('상담신청이 완료되었습니다', {
          //   varient: 'success',
          // });
          // this.$analytics.fbq.event('SubmitApplication');
          this.$gtag.event('complete_application', {
            description: '신청 완료',
          });
        }
      },
    );
  }

  // 상담완료자 수
  getConsultTotalCount() {
    ConsultResultV3Service.getCounsultCount().subscribe(res => {
      if (res) {
        this.consultTotalCount = res.data;
      }
    });
  }

  // 스크롤 이벤트
  handleScroll() {
    const scrollTop = window.scrollY || window.pageYOffset;
    const target = this.$refs.container;

    if (target) {
      const rect = target.getBoundingClientRect().top;
      if (rect < 0) {
        this.isVisibleStickyBtn = true;
        this.headerBgOpacity = 1;
        setTimeout(() => {
          this.consultTotalCountSticky = this.consultTotalCount;
        }, 1000);
      } else {
        this.isVisibleStickyBtn = false;
        this.headerBgOpacity =
          1 - (target.offsetTop - scrollTop) / target.offsetTop;
      }
    }
  }

  // get fnb owner status
  getFnbOwnerStatus() {
    const urlQuery = location.search;
    const params = Object.fromEntries(new URLSearchParams(urlQuery));
    if (urlQuery) {
      this.fnbOwnerStatus = params.fnbOwnerStatus.toUpperCase();
    }
    // console.log(this.fnbOwnerStatus);
    this.$gtag.event(`user_type_${this.fnbOwnerStatus}`);
  }

  // get proformar id
  getProformaId() {
    this.proformaConsultResultV3CreateDto.ipAddress = this.ipAddress;
    this.proformaConsultResultV3CreateDto.fnbOwnerStatus = this.fnbOwnerStatus;
    ConsultResultV3Service.getProformaId(
      this.proformaConsultResultV3CreateDto,
    ).subscribe(res => {
      if (res) {
        this.consultRequestDto.proformaConsultResultId = res.data.id;
        // console.log(this.consultRequestDto.proformaConsultResultId);
      }
    });
  }

  kakaoChat() {
    window.Kakao.Channel.chat({
      channelPublicId: '_rxlgjs',
    });
  }

  created() {
    this.getFnbOwnerStatus();
    this.getConsultTotalCount();
    window.addEventListener('scroll', this.handleScroll);
  }

  mounted() {
    axios.get('https://api.ipify.org?format=json').then(res => {
      this.ipAddress = res.data.ip;
      this.getProformaId();
    });
  }
}
