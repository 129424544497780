























































































































































































































































































































































































































































































































































































































import BaseComponent from '@/core/base.component';
import { Component } from 'vue-property-decorator';
import Register from '../Register.vue';
import RadarChart from '../../_components/charts/RadarChart.vue';

@Component({
  name: 'NewFnbOwner',
  components: {
    RadarChart,
  },
})
export default class NewFnbOwner extends BaseComponent {
  $refs!: {
    section01: HTMLFormElement;
    section03: HTMLFormElement;
    radarChart: HTMLCanvasElement;
    mySwiper: HTMLFormElement;
  };

  private chartIndex = 0;
  private chartArray: any[] = [
    {
      borderColor: 'rgba(11,83,141,1)',
      backgroundColor: 'rgba(11,83,141,0.25)',
      data: [40, 60, 80, 60, 70],
    },
    {
      borderColor: 'rgba(11,83,141,1)',
      backgroundColor: 'rgba(11,83,141,0.25)',
      data: [50, 30, 40, 90, 30],
    },
    {
      borderColor: 'rgba(11,83,141,1)',
      backgroundColor: 'rgba(11,83,141,0.25)',
      data: [20, 70, 50, 30, 80],
    },
  ];
  private chartArrayData = this.chartArray[0];

  get chartData() {
    return {
      datasets: [this.chartArray[this.chartIndex]],
      labels: [
        '빅데이터 상권지수',
        '구매력',
        '창업비용',
        '조리경험',
        '운영경험',
      ],
    };
  }

  private isChartVisible = false;

  private currentIdx = 0;
  private swiperOption: any = {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    loop: true,
    grabCursor: false,
    allowTouchMove: false,
    speed: 1000,
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
  };

  private isKitchenVisible = false;
  private swiperKitchen: any = {
    slidesPerView: 'auto',
    loop: true,
    grabCursor: false,
    allowTouchMove: false,
    speed: 1000,
    spaceBetween: 20,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  };

  get swiper() {
    return this.$refs.mySwiper.$swiper;
  }

  onSwipe(varuable: any) {
    let idx = varuable.swiper.activeIndex - 1;
    if (idx > 2) {
      idx = 0;
    }
    return (this.currentIdx = idx);
  }

  // 창업 아이템 분석 정보 더보기
  onClickAnalysisDetail() {
    setTimeout(() => {
      this.isChartVisible = !this.isChartVisible;
    }, 100);
    this.updateChartData();
  }

  updateChartData() {
    setInterval(() => {
      this.chartIndex++;
      if (this.chartIndex === this.chartArray.length) this.chartIndex = 0;
    }, 3000);
  }

  // 공유주방 정보 더보기
  onClickKitchenDetail() {
    this.isKitchenVisible = !this.isKitchenVisible;
  }

  handleScroll() {
    const scrollTop = window.scrollY || window.pageYOffset;
    const target = this.$refs.section01;
    const section03 = this.$refs.section03;

    if (target) {
      const rect = target.getBoundingClientRect().top;
      if (rect < 300) {
        this.$refs.section01.classList.add('is-start');
        this.swiper.autoplay.start();
        this.swiper.on('slideChange', () => {
          this.onSwipe(this);
        });
      }
    }

    if (section03) {
      const rect = section03.getBoundingClientRect().top;
      if (rect < 300) {
        this.$refs.section03.classList.add('is-start');
      }
    }
  }

  mounted() {
    this.swiper.autoplay.stop();
  }
}
